$root-size: 18px;
$responsive-min: 320px;
$responsive-max: 1065px;
$transition-time: 0.25s;
$col-order-break-at: 595px;

$color-primary: #db0011;
$color-blue: #c7e9fa;

$color-white-900: #ffffff;
$color-white-875: #f1f3f4;
$color-white-850: #f0f0f0;
$color-white-800: #e1e0e1;
$color-white-810: #e6e6e6;
$color-white-775: #e5e5e5;
$color-white-750: #e7e9ec;
$color-white-600: #d1d1d1;
$color-black-400: #464646;
$color-black-175: #171723;
$color-black-150: #090909;
$color-black-100: #000000;

$font-primary: "Univers Next for HSBC", sans-serif;
