@import "vars";
@import "mixins";
@import "fonts";
@import "reset";
@import "breakpoints";

html {
  $font-size: $root-size;
  @include fluid-attr(font-size, $font-size * 0.75, $font-size);
  font-family: $font-primary;
  line-height: 1.2;
}

.button {
  $font-size: 24px;
  background-color: #fff;
  border: 2px solid black;
  border-radius: 10px;
  box-shadow: 1px 1px 0 0 #000;
  font-weight: 400;
  outline: 0;
  padding: 0.75em 2em;
  text-align: center;
  @include fluid-attr(font-size, $font-size * 0.75, $font-size);

  &:focus {
    box-shadow: 1px 1px 0 0 $color-primary;
    border: 2px solid $color-primary;
  }
}

.align-self-start {
  align-self: flex-start;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: flex-end;
}

.text-underline {
  text-decoration: underline;
}

.sm-margin-top-bottom-2 {
  $min-margin: 1em;
  $max-margin: 2em;
  @include fluid-attr(margin-bottom, $min-margin, $max-margin);
  @include fluid-attr(margin-top, $min-margin, $max-margin);
  @media screen and (min-width: $col-order-break-at) {
    margin-bottom: 1em;
    margin-top: 0;
  }
}

.brush-paint-background {
  display: inline-block;
  margin-bottom: -20px;
  background: url("/assets/bg_red_paint.png") 0 50% / cover no-repeat;
  padding: 0.1875em 0.125em 0.3125em 0.5em;
  color: $color-white-900;

  @media screen and (max-width: 400px) {
    max-width: 315px;
  }
}

.brush-paint-background-end {
  display: inline-block;
  background: url(/assets/bg_red_paint_end.png) 100% 0 / cover no-repeat;
  padding: 0.1875em 0.25em 0.3125em;
  margin-right: 0.125em;
}

.font-weight-100 {
  font-weight: 100;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-300 {
  font-weight: 400;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.justify-between {
  justify-content: space-between;
}

.d-block {
  @media screen and (max-width: 450px) {
    display: block !important;
  }
}
.d-md-none {
  @media screen and (min-width: 451px) {
    display: none !important;
  }
}

.content--blank {
  strong {
    font-weight: bold;
  }
}

.content {
  $font-size: 18px;
  @include fluid-attr(font-size, $font-size * 0.75, $font-size);
  color: black;
  line-height: 1.366;
  font-weight: 400;

  &.content--hidden {
    opacity: 0;
    transition: opacity ease-in-out 0.5s;
  }

  &.content--visible {
    opacity: 1;
    transition: opacity ease-in-out 0.5s;
  }
  p {
    margin-bottom: 1em;

    &.red {
      color: #db0011;
    }
  }

  &--no-margin-bottom {
    p {
      margin-bottom: 0;
    }
    ul {
      margin-top: 0;
    }
  }

  strong {
    font-weight: bold;
  }

  hr {
    border: none;
    border-top: 1px solid $color-primary;
  }

  ul,
  ol {
    padding-left: 1.1em;

    li {
      color: $color-black-100;
    }

    &.red li {
      color: $color-primary;
    }
  }

  &--max-width-460 {
    max-width: 460px;
  }

  &--list-notation-red {
    ul,
    ol {
      li {
        color: $color-primary;
      }
    }
  }

  &--blank {
    display: inline;
  }

  span {
    &.red-title {
      color: #db0011;
      font-weight: 600;
    }
  }
}

.text-highlight {
  background-color: #f5b3b7;
}

.text-red {
  color: #db0011;
}

.text-green {
  //color: #40b045;
}

.text-red {
  text-decoration: underline;
}

.text-boldunderlined {
  font-weight: bold;
  text-decoration: underline;
}

//quick fix
.app-browser--ie-11 {
  &.app-lang-jp {
    .brush-paint-background {
      position: relative;
      top: -13px;
    }

    .page--candidate {
      .brush-paint-background {
        top: -5px;
      }
    }

    .title--black-400 {
      .brush-paint-background {
        top: -7px;
      }
    }
  }
}

.layer-bottom {
  position: relative;
  z-index: -1;
}

.background-salmon {
  background-color: #C7E9FA;
  padding: 1rem;
}

.long-term-success__box {
  border-radius: 25px;
}

.rounded-lg {
  border-radius: 25px;
}

.pill-title {
  all: unset;
  border-radius: 25px;
  background: #db0011;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 38.4px;
  padding-inline: 18px;
  min-height: 39px;
  padding-block: 5px;
  margin-left: -20px;
}

.title2 {
  all: unset;
  font-size: 55px;
  font-style: normal;
  font-weight: 100;
  line-height: 38.4px;
}

strong {
  font-weight: 600;
}