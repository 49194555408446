@font-face {
  font-family: "Univers Next for HSBC";
  src: url("/fonts/UniversNextforHSBC-Medium.woff2") format("woff2"),
  url("/fonts/UniversNextforHSBC-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Univers Next for HSBC";
  src: url("/fonts/UniversNextforHSBC-Light.woff2") format("woff2"),
  url("/fonts/UniversNextforHSBC-Light.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Univers Next for HSBC";
  src: url("/fonts/UniversNextforHSBC-Bold.woff2") format("woff2"),
  url("/fonts/UniversNextforHSBC-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Univers Next for HSBC";
  src: url("/fonts/UniversNextforHSBC-Regular.woff2") format("woff2"),
  url("/fonts/UniversNextforHSBC-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Univers Next for HSBC";
  src: url("/fonts/UniversNextforHSBC-Thin.woff2") format("woff2"),
  url("/fonts/UniversNextforHSBC-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
