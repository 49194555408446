@import "../../styles/mixins";

.app {
  width: 100%;
  height: 100%;

  &-browser--ie-11 {
    .carousel {
      overflow: hidden;
    }
  }

  &-browser--ie-10 {
    .carousel {
      overflow: hidden;
    }
  }

  &-lang {
    &-ar {
      direction: rtl;

      .title-graphic,
      [class^='brush-paint-background']{
        transform: scaleX(-1);
      }

      .brush-paint-background-end {
        margin-left: 0.125em;
        margin-right: 0;
      }

      .video-transcript-link img {
        margin: 0.25em 0.5em 0 0;
      }

      .accordion-title {
        margin-left: auto;
        margin-right: 0;
      }

      .QuestionCard {
        label {
          padding-left: 0;
          padding-right: 2.5em;
        }

        &--answers {
          position: relative;
          overflow: hidden;
        }

        input[type=checkbox] {
          right: -999em;
          left: 0;

          & + label::before {
            right: 0;
            left: auto;
          }
          & + label::after {
            right: -4px;
            left: auto;
          }
        }
      }

      .carousel-nav-frame .arrow.nav-right {
        left: 40%;
        right: auto;
        transform: rotate(135deg) translate(0);
      }

      .carousel-nav-frame .arrow.nav-left {
        right: 40%;
        left: auto;
        transform: rotate(-45deg) translate(0);
      }

      .step h3 {
        margin-right: 0;
        margin-left: 30px;
      }

      .module-link-cta-text {
        margin-left: 0;
        margin-right: auto;

        img {
          margin-left: 0;
          margin-right: 0.5em;
          transform: scaleX(-1);
        }
      }
    }
  }
}
